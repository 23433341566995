import React, { useContext, useEffect } from "react";
import "../App.scss";
import { useNavigate } from "react-router-dom";
import roIcon from "../assets/images/RO.png";
import huIcon from "../assets/images/HU.png";
import usIcon from "../assets/images/US.png";
import config from "../config.json";
import { LanguageContext } from "../context/languageContext";
import i18next from "i18next";

const LanguagePage = () => {
  const navigate = useNavigate();

  const langCtx = useContext(LanguageContext);
  const defaultLanguage = langCtx.language;

  const setHuLanguage = () => {
    langCtx.setLanguage("hu");
    i18next.changeLanguage("hu");
    navigate("/login");
  };

  const setRoLanguage = () => {
    langCtx.setLanguage("ro");
    i18next.changeLanguage("ro");
    navigate("/login");
  };

  const setEngLanguage = () => {
    langCtx.setLanguage("en");
    i18next.changeLanguage("en");
    navigate("/login");
  };

  return (
    <div className="language-page">
      <h2
        className="page-title"
        style={{
          color: config.MUZEUM.titleColor,
        }}
      >
        Alege limba/
        <br /> válaszd ki a nyelvet/
        <br /> Select your language
      </h2>
      <div className="language-buttons-holder">
        <button className="language-button" onClick={setRoLanguage} style={{backgroundColor: config.MUZEUM.textColor}}>
          <img src={roIcon}></img>Română
        </button>
        <button className="language-button" onClick={setHuLanguage} style={{backgroundColor: config.MUZEUM.textColor}}>
          <img src={huIcon}></img>Magyar
        </button>
        <button className="language-button" onClick={setEngLanguage} style={{backgroundColor: config.MUZEUM.textColor}}>
          <img src={usIcon}></img>English
        </button>
      </div>
    </div>
  );
};

export default LanguagePage;