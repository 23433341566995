import React, { useState, useEffect, useContext } from "react";
import QrReader from "react-qr-reader";
import "./QRScanner.scss";
import { useNavigate } from "react-router-dom";
import { ClickSourceContext } from "../context/clickSourceContext";
import cclogout from "../assets/images/cclogout.svg";
import upArrow from "../assets/images/arrow-gif.gif";
import { useTranslation } from "react-i18next";

import Web3 from "web3";
import { setTimeout } from "timers";
var web3 = new Web3();

const QrScanner = ({ setWalletAddress, children }) => {
  const [showDialog, setDiaglog] = useState(false);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const sourceCtx = useContext(ClickSourceContext);
  const [addressError, setaddressError] = useState(null);

  const { t } = useTranslation();

  const handleScan = (scanData) => {
    if (scanData) {
      //console.log(scanData, "scandata");
      if (sourceCtx.isCimerMint === false) {
        const regexForUrl =
          /(ethereum:|.*\?.*address=)?(0x[a-fA-F0-9]{40})(@[0-9]+)?$/;
        try {
          const checkedUrl = scanData.match(regexForUrl)[0];
          const regexForAddress = /0x[a-fA-F0-9]{40}/;
          const extractedAddress = checkedUrl.match(regexForAddress)[0];

          const address = extractedAddress.toLowerCase();
          setWalletAddress(address);
          sourceCtx.setScannedAddress(address);
          navigate("/mint");
        } catch (err) {
          setaddressError(err);
          setTimeout(() => {
            navigate("/home");
          }, 5000);
        }
      } else {
        try {
          const scannedObj = JSON.parse(scanData);
          //console.log(scannedObj, "parsed scanned obj");

          const address = scannedObj.address;
          const dna = scannedObj.dna;

          setWalletAddress(address);
          sourceCtx.setScannedAddress(address);
          sourceCtx.setDna(dna);
          navigate("/mint");
        } catch (error) {
          setaddressError(error);
          setTimeout(() => {
            navigate("/home");
          }, 5000);
        }
      }
    }
  };

  useEffect(() => {
    setWalletAddress(null);
    sourceCtx.setScannedAddress(null);
  }, []);

  const handleNavigate = () => {
    navigate("/home");
  };

  const onError = () => {
    console.log("scanner error");
  };

  console.log(addressError, 'address error')

  return (
    <>
      {!addressError ? (
        <div className="scanner-holder">
          <button className="qr-close-btn" onClick={handleNavigate}>
            <img src={cclogout} />
          </button>
          {window.outerWidth > 500 && (
            <div className="scan-instructions">
              <img src={upArrow} className="up-arrow"></img>
              <p className="scanner-text">{t("scannerText")}</p>
            </div>
          )}

          {!showDialog && !processing && (
            <QrReader
              delay={500}
              onScan={handleScan}
              constraints={{
                facingMode: "environment",
              }}
              className="qr-reader"
              onError={onError}
              willReadFrequently={true}
            />
          )}
          {children}
        </div>
      ) : (
        <div className="addressError">
          {addressError.stack.includes("Unexpected non-whitespace character after JSON at position 1")
            ? "Hibás wallet address, szkenneld be a címer készítő applikációban generált QR kódot!"
            : "Hibás wallet address, szkenneld be a kártyádon található QR kódot!"}
        </div>
      )}
    </>
  );
};

export default QrScanner;
