import { createContext, useState } from "react";

export const ClickSourceContext = createContext({
  sourceId: null,
  setSourceId: () => {},
  scannedAddress: null,
  setScannedAddress: () => {},
  collectionId: null,
  setCollectionId: () => {},
  selectedCollection: null,
  setSelectedCollection: () => {},
  preSelectedCollection: null,
  choosePreSelectedCollection: () => {},
  isKiosk: null,
  setIsKiosk: () => {},
  isCimerMint: false,
  setIsCimerMint: () => {},
  dna: null,
  setDna: () => {},
  showPin: false,
  setShowPin: () => {}
});

const ClickSourceContextProvider = (props) => {
  const [clickSourceId, setClickSourceId] = useState("");
  const [address, setScannedAddress] = useState("");
  const [collId, setCollId] = useState("");
  const [collection, setCollection] = useState(null);
  const [preSelected, setPreSelected] = useState(null);
  const [kiosk, setKiosk] = useState(false);
  const [cimer, setcimer] = useState(false);
  const [dns, setdns] = useState(null);
  const [showpin, setshowpin] = useState(false);

  const config = process.env.REACT_APP_CONFIG;

  const setSource = (id) => {
    setClickSourceId(id);
  };

  const setAddress = (addr) => {
    setScannedAddress(addr);
  };

  const setCollectionId = (id) => {
    setCollId(id);
  };

  const setSelectedCollection = (collection) => {
    setCollection(collection);
  };

  const setPreSelectedCollection = (collection) => {
    setPreSelected(collection);
  };

  const setIsKiosk = () => {
    setKiosk(true);
  };

  const setIsCimerMint = () => {
    setcimer(true);
  };

  const setQrDna = (qrDna) => {
    setdns(qrDna)
  }

  const setIfShowPin = () => {
    setshowpin(true)
  }

  return (
    <ClickSourceContext.Provider
      value={{
        sourceId: clickSourceId,
        setSourceId: setSource,
        scannedAddress: address,
        setScannedAddress: setScannedAddress,
        collectionId: collId,
        setCollectionId: setCollId,
        selectedCollection: collection,
        setSelectedCollection: setCollection,
        preSelectedCollection: preSelected,
        setPreSelectedCollection: setPreSelected,
        isKiosk: kiosk,
        setIsKiosk: setKiosk,
        isCimerMint: cimer,
        setIsCimerMint: setcimer,
        dna: dns,
        setDna: setdns,
        showPin: showpin,
        setShowPin: setshowpin
      }}
    >
      {props.children}
    </ClickSourceContext.Provider>
  );
};

export default ClickSourceContextProvider;
