import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ClickSourceContext } from "../context/clickSourceContext";
import Logo from "../components/Logo";
import "../components/QRScanner.scss";
import config from "../config.json";
import PinCodeInput from "../components/PinCodeInput";
import ccexit from "../assets/images/ccexit.svg";
import Card from "../components/wallet components/Card";
import { useTranslation } from "react-i18next";

const Home = () => {
  const navigate = useNavigate();
  const sourceCtx = useContext(ClickSourceContext);

  const { t } = useTranslation();

  const selectedCollection = sourceCtx.selectedCollection;

  useEffect(() => {
    window.scrollTo(0, 0);
    let stationID = window.sessionStorage.getItem("stationId");
    if (!stationID) {
      navigate("/");
    }
  }, []);

  const goToQRReader = () => {
    sourceCtx.setIsCimerMint(false)
    navigate({ pathname: "/scanner", replace: true });
  };

  const logOut = () => {
    sourceCtx.setShowPin(true);
  };

  const goToHome = () => {
    sourceCtx.setShowPin(false);
  };

  const goToCimerMint = () => {
    sourceCtx.setIsCimerMint(true);
    navigate({ pathname: "/scanner", replace: true });
  };

  //console.log(sourceCtx.showPin, 'ctx showpin homepage')

  return (
    <>
      {sourceCtx.showPin === false ? (
        <div className="dashboard-container">
          <Logo />
          <div className="logout-btn" onClick={logOut}>
            <img src={ccexit} />
          </div>
          <div className="station-container">
            <div className="station-number">
              <p
                className="experience"
                style={{
                  color: config.MUZEUM.titleColor,
                }}
              >
                {/* {config.MUZEUM.title} */}
                {t('mainTitle')}
              </p>
              <p
                className="experience-text"
                style={{
                  color: config.MUZEUM.textColor,
                  fontFamily: config.MUZEUM.font,
                }}
              >
              {t('scanText')}
              </p>

              <div className="experience-div">
                <div className="home-card">
                  <Card
                    key={selectedCollection.id}
                    name={selectedCollection.name}
                    collectionUri={selectedCollection.uri}
                    collectionId={selectedCollection.id}
                  />
                </div>

                <div className="home-button-holder">
                  <button
                    className="home-button"
                    onClick={goToQRReader}
                    style={{ backgroundColor: "#5a513f", color: "white" }}
                  >
                     {t('getExperienceButton')}
                  </button>
                </div>
              </div>
              <p
                className="experience-text"
                style={{ marginTop: "15px", marginBottom: "30px" }}
              >
                {t('or')}
              </p>
              <div className="cimer-texts">
                <p className="experience-text" style={{ marginBottom: "10px" }}>
                {t('crestText')}
                </p>
                <button
                  onClick={goToCimerMint}
                  className="home-button"
                  id="cimer"
                  style={{ backgroundColor: "#5a513f", color: "white" }}
                >
                {t('getCrestButton')}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <PinCodeInput
          title="Írd be a pin kódodat"
          goToHomePage={goToHome}
          withInstruction="false"
        />
      )}
    </>
  );
};

export default Home;
