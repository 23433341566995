import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from './Logo';
import axios from 'axios';
import Keyboard from 'react-virtual-keyboard';
import config from '../config.json';
import ccgoBackIcon from '../assets/images/ccback.svg';
import { ClickSourceContext } from '../context/clickSourceContext';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../context/languageContext';

const PinCodeInput = ({ title, withInstruction, goToHomePage }) => {
  const inputs = document.querySelectorAll('input');
  const pinInput = document.getElementsByClassName(
    'ui-keyboard-input ui-widget-content ui-corner-all ui-keyboard-input-current'
  )[0];
  const [errorText, setErrorText] = useState('');
  const [callError, setCallError] = useState(false);
  const [firstNum, setFirstNum] = useState(null);
  const [secondNum, setSecondNum] = useState(null);
  const [thirdNum, setThirdNum] = useState(null);
  const [fourthNum, setFourthNum] = useState(null);
  const [showWarningText, setshowWarningText] = useState(false);
  const [shownVirtualKeyboard, setshownVirtualKeyboard] = useState(false);
  const [inputField, setInputField] = useState('');

  const [pinIsCorrect, setPinIsCorrect] = useState(false);

  const secondInputRef = useRef(null);
  const thirdInputRef = useRef(null);
  const fourthInputRef = useRef(null);

  const timerRef = useRef(null);

  const ctx = useContext(ClickSourceContext);
  const langContext = useContext(LanguageContext);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const [timeoutId, setTimeoutId] = useState(null);

  const goToDashBoard = () => {
    navigate({ pathname: '/dashboard', replace: true });
  };

  function isTouchDevice() {
    return (
      (('ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0) &&
        window.outerWidth >= 1920) ||
      window.outerWidth === 1904
    );
  }
  let timeout;

  useEffect(() => {
    const isKiosk = isTouchDevice();
    //console.log(isKiosk, "is kiosk");
    if (isKiosk) {
      setshownVirtualKeyboard(true);
      ctx.setIsKiosk(true);
    } else {
      setshownVirtualKeyboard(false);
    }

    // if(pinIsCorrect){
    //   ctx.setShowPin(false);
    // }
    console.log(ctx.showPin, 'ctx showpin');

    const id = setTimeout(() => {
      console.log('Timeout fired!');
      console.log(timeoutId, 'timeout id when fired');
      console.log(ctx.showPin, 'showpin in timeout');
      ctx.setShowPin(false);
    }, 10000);
    setTimeoutId(id);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (inputField.length === 4) {
      const first = inputField.substring(0, 1);
      const second = inputField.substring(1, 2);
      const third = inputField.substring(2, 3);
      const fourth = inputField.substring(3, 4);

      setFirstNum(first);
      setSecondNum(second);
      setThirdNum(third);
      setFourthNum(fourth);
    }
  }, [inputField]);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    if ((firstNum, secondNum, thirdNum, fourthNum)) {
      let pin = firstNum + '' + secondNum + '' + thirdNum + '' + fourthNum;
      if (withInstruction === 'false') {
        const existingPin = sessionStorage.getItem('stationId');
        if (pin !== existingPin) {
          setshowWarningText(true);
          if (pinInput) {
            pinInput.classList.add('incorrect-pin');
            pinInput.addEventListener('click', () => {
              setshowWarningText(false);
              pinInput.value = '';
              pinInput.classList.remove('incorrect-pin');
            });
            return;
          } else if (!shownVirtualKeyboard) {
            inputs.forEach((input) => {
              input.classList.add('incorrect');
              input.addEventListener('click', () => {
                inputs.forEach((input) => {
                  setshowWarningText(false);
                  input.value = '';
                  input.classList.remove('incorrect');
                });
              });
            });
          }
        }
      }
      const Login = async () => {
        await axios
          .post(backendUrl + 'authenticate', { PIN: pin })
          .then((response) => {
            let respData = response.data;
            if (respData.error) {
              if (!shownVirtualKeyboard) {
                setshowWarningText(true);
                inputs.forEach((input) => {
                  input.classList.add('incorrect-pin');
                  input.addEventListener('click', () => {
                    inputs.forEach((input) => {
                      setshowWarningText(false);
                      input.value = '';
                      input.classList.remove('incorrect-pin');
                    });
                  });
                });
              }
            } else {
              window.sessionStorage.setItem('stationId', pin);

              setPinIsCorrect(true);

              const keypad = document.getElementsByClassName(
                'ui-keyboard ui-widget-content ui-widget ui-corner-all ui-helper-clearfix ui-keyboard-has-focus'
              )[0];
              if (keypad) {
                keypad.style.visibility = 'hidden';
              }

              console.log(timeoutId, 'timeout id');
              ctx.setShowPin(false);
              clearTimeout(timeoutId);
              ctx.setShowPin(false);
              goToDashBoard();
            }
          })
          .catch((e) => {
            if (!shownVirtualKeyboard) {
              setshowWarningText(true);
              inputs.forEach((input) => {
                input.classList.add('incorrect');
                input.addEventListener('click', () => {
                  inputs.forEach((input) => {
                    setshowWarningText(false);
                    input.value = '';
                    input.classList.remove('incorrect');
                  });
                });
              });
            } else {
              setshowWarningText(true);
              pinInput.classList.add('incorrect-pin');
              pinInput.addEventListener('click', () => {
                setshowWarningText(false);
                pinInput.value = '';
                pinInput.classList.remove('incorrect-pin');
              });
            }

            setFirstNum(null);
            setSecondNum(null);
            setThirdNum(null);
            setFourthNum(null);
          });
      };
      Login();
    }
  }, [firstNum, secondNum, thirdNum, fourthNum]);

  return (
    <>
      <Logo />
      {withInstruction === 'false' && (
        <img
          src={ccgoBackIcon}
          className="goback-icon"
          onClick={goToHomePage}
        ></img>
      )}
      <form className="pin-form">
        <div className="pin-input">
          <h1
            className="title-home"
            style={{
              color: config.MUZEUM.titleColor,
              fontFamily: config.MUZEUM.titleFont,
              marginBottom: title === 'Írd be a pin kódodat' ? '40px' : '16px',
            }}
          >
            {t('mainTitle')}
            {/* {title} */}
          </h1>
          {withInstruction === 'true' && (
            <p className="enter-pin" style={{ color: config.MUZEUM.textColor }}>
              {t('pinText')}
            </p>
          )}
          <div className="inputs">
            {shownVirtualKeyboard ? (
              <Keyboard
                value={inputField}
                type="password"
                maxLength="4"
                options={{
                  usePreview: false,
                  type: 'password',
                  layout: 'custom',
                  autoAccept: true,
                  alwaysOpen: false,
                  color: 'light',
                  customLayout: {
                    default: ['7 8 9', '4 5 6', '1 2 3', '0 {bksp} {enter}'],
                  },
                }}
                onChange={setInputField}
              />
            ) : (
              <>
                <input
                  type="password"
                  inputMode="numeric"
                  className="input-field"
                  maxLength="1"
                  onChange={(e) => {
                    setFirstNum(e.target.value);
                    secondInputRef.current.focus();
                  }}
                ></input>
                <input
                  type="password"
                  inputMode="numeric"
                  className="input-field"
                  maxLength="1"
                  ref={secondInputRef}
                  onChange={(e) => {
                    setSecondNum(e.target.value);
                    thirdInputRef.current.focus();
                  }}
                ></input>
                <input
                  type="password"
                  inputMode="numeric"
                  className="input-field"
                  maxLength="1"
                  ref={thirdInputRef}
                  onChange={(e) => {
                    setThirdNum(e.target.value);
                    fourthInputRef.current.focus();
                  }}
                ></input>
                <input
                  type="password"
                  inputMode="numeric"
                  className="input-field"
                  maxLength="1"
                  ref={fourthInputRef}
                  onChange={(e) => setFourthNum(e.target.value)}
                ></input>
              </>
            )}
          </div>
        </div>

        {showWarningText && (
          <div className="warning-text">
            <p>Hibás pin kód, írd be újra!</p>
          </div>
        )}
        {callError && (
          <>
            <div className="warning-text">
              <p>{errorText}</p>
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default PinCodeInput;
