import { createContext, useState } from "react";

export const LanguageContext = createContext({
  language: "hu",
  setLanguage: () => {},
});

const LanguageContextProvider = (props) => {
  const [selectedLanguage, setselectedLanguage] = useState(localStorage.getItem("lang") ? localStorage.getItem("lang") : "hu");

  const setLanguage = (lang) => {
    localStorage.setItem("lang", lang)
    setselectedLanguage(lang);
  };

  return (
    <LanguageContext.Provider
      value={{ language: selectedLanguage, setLanguage: setLanguage }}
    >
      {props.children}
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;
