import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClickSourceContext } from '../context/clickSourceContext';
import Logo from '../components/Logo';
import config from '../config.json';
import { getCollections } from '../contracts/contractMethdos/contractMethdos';
import Card from '../components/wallet components/Card';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const navigate = useNavigate();
  const sourceCtx = useContext(ClickSourceContext);
  const [collections, setCollections] = useState([]);
  const [error, setError] = useState(null);

  const [preselectedCollection, setpreselectedCollection] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      await getCollections()
        .then((response) => {
          if (response.length > 0) {
            setCollections(response);
          }
        })
        .catch((e) => {
          console.log(e, 'error');
          setError(e);
        });
    };
    fetchData();
  }, []);

  const chooseCollection = (collection) => {
    setpreselectedCollection(collection);
  };

  const goToHomepage = () => {
    const { id } = preselectedCollection;
    sourceCtx.setCollectionId(id);
    sourceCtx.setSelectedCollection(preselectedCollection);

    navigate('/home');
  };

  const goBackToAllCollections = () => {
    setpreselectedCollection(null);
  };

  return (
    <div className="dashboard-container">
      <Logo />
      <div className="station-container">
        <div className="station-number">
          <p
            className="experience"
            style={{
              color: config.MUZEUM.titleColor,
              fontFamily: config.MUZEUM.titleFont,
            }}
          >
            {/* {config.MUZEUM.title} */}
            {t('mainTitle')}
          </p>
          <p
            className="experience-text"
            style={{
              color: config.MUZEUM.textColor,
              fontFamily: config.MUZEUM.font,
            }}
          >
            {t('dashboardText')}
          </p>
          {!preselectedCollection ? (
            <div className="experience-holder">
              {collections.length !== 0 &&
                collections
                  .slice(0)
                  .reverse()
                  .map((collection) => {
                    return (
                      <Card
                        key={collection.id}
                        name={collection.name}
                        collectionUri={collection.uri}
                        collectionId={collection.id}
                        onClick={() => chooseCollection(collection)}
                        isOwned={false}
                      />
                    );
                  })}
            </div>
          ) : (
            <div>
              <Card
                key={preselectedCollection.id}
                name={preselectedCollection.name}
                collectionUri={preselectedCollection.uri}
                collectionId={preselectedCollection.id}
                isOwned={false}
              />
              <div className="choose-buttons">
                <button
                  className="back"
                  onClick={goBackToAllCollections}
                  style={{
                    backgroundColor: '#ffffff',
                    borderColor: '#5a513f',
                    color: '#5a513f',
                    fontFamily: config.MUZEUM.titleFont,
                  }}
                >
                  {t('backButton')}
                </button>
                <button
                  className="choose"
                  onClick={goToHomepage}
                  style={{
                    backgroundColor: '#5a513f',
                    borderColor: '#5a513f',
                    color: 'white',
                    fontFamily: config.MUZEUM.titleFont,
                  }}
                >
                  {t('chooseButton')}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
