import React from "react";
import "./App.scss";
import routes from "./routes/Routes";
import { Routes, Route, HashRouter, BrowserRouter } from "react-router-dom";
import ClickSourceContextProvider from "./context/clickSourceContext";
import LanguageContextProvider from "./context/languageContext";

function App() {
  React.useEffect(() => {
    window.addEventListener("contextmenu", function(e) { e.preventDefault(); })
  });

  return (
    <LanguageContextProvider>
    <ClickSourceContextProvider>
      <HashRouter basename="/">
        <Routes>
          {routes.map(({ path, element, exact }) => (
            <Route key={path} path={path} exact={exact} element={element} />
          ))}
        </Routes>
      </HashRouter>
    </ClickSourceContextProvider>
    </LanguageContextProvider>
  );
}

export default App;
