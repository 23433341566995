import React, { useContext, useEffect, useState } from "react";
import PinCodeInput from "../components/PinCodeInput";
import config from "../config.json";
import Footer from "../components/wallet components/Footer";

const Login = () => {

  return (
    <div className="loginpage">
      <PinCodeInput title={config.MUZEUM.title} withInstruction="true"/>
      <Footer />
    </div>
  );
};

export default Login;
