import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ClickSourceContext } from "../context/clickSourceContext";
import axios from "axios";
import Logo from "../components/Logo";
import ClipLoader from "react-spinners/ClipLoader";
import CryptoJS from "crypto-js";
import config from "../config.json";
import Card from "../components/wallet components/Card";
import { useTranslation } from "react-i18next";

const MintFeedback = () => {
  const sourceCtx = useContext(ClickSourceContext);
  const [mintSuccess, setMintSuccess] = useState(null);
  const [errorText, setErrorText] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();

  const selectedCollection = sourceCtx.selectedCollection;

  const { t } = useTranslation();

  useEffect(() => {
    if (!sourceCtx.scannedAddress) {
      navigate("/");
    }
  }, []);

  const goToNfts = () => {
    navigate({ pathname: "/nfts", replace: true });
  };
  const goBack = () => {
    navigate({ pathname: "/home", replace: true });
  };

  const goToScanner = () => {
    navigate({ pathname: "/scanner", replace: true });
  };

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const password = process.env.REACT_APP_PASSWORD;

  function dnaArrayToString(dna) {
    let dnaString = "0x";
    dna.forEach((dnaElement) => {
      if (dnaElement < 16) {
        dnaString += "0";
      }
      const hex = dnaElement.toString(16);
      dnaString += hex;
    });
    return dnaString;
  }

  useEffect(() => {
    const Mint = async () => {
      let PIN = sessionStorage.getItem("stationId");
      let collectionId = sourceCtx.collectionId;
      console.log(collectionId, "collection id");
      let userAddress = sourceCtx.scannedAddress;
      console.log(userAddress, "user address");
      const phraseToHash = collectionId + ":" + password + ":" + userAddress;
      let hash = CryptoJS.MD5(phraseToHash).toString();

      setLoading(true);

      if (sourceCtx.isCimerMint) {
        const dnaFromCtx = sourceCtx.dna;
        console.log(dnaFromCtx, "dns mintnel");
        const dna = dnaArrayToString(dnaFromCtx);
        console.log(dna, 'string dna');
        const crestPhraseToHash = dna + ":" + password + ":" + userAddress;
        const crestHash = CryptoJS.MD5(crestPhraseToHash).toString();

        console.log("muzeum mint");

        await axios
          .post(backendUrl + "mintCrest", {
            dna: dna,
            walletAddress: userAddress,
            md5Hash: crestHash,
          })
          .then((response) => {
            console.log(response, "resp");
            let respStatus = response.status;
            if (respStatus !== 200) {
              setMintSuccess(false);
              setErrorText(response.data.message, "resp");
            } else {
              setMintSuccess(true);
            }
          })
          .catch((e) => {
            console.log(e, " error");
            setMintSuccess(false);
            setErrorText(e.response.data.message);
          });
      } else {
        await axios
          .post(backendUrl + "mintExperienceCard", {
            collectionId: collectionId,
            walletAddress: userAddress,
            md5Hash: hash,
          })
          .then((response) => {
            console.log(response, "resp");
            let respStatus = response.status;
            if (respStatus !== 200) {
              setMintSuccess(false);
              setErrorText(response.data.message, "resp");
            } else {
              setMintSuccess(true);
              //setLoading(false);
            }
          })
          .catch((e) => {
            console.log(e, " error");
            setMintSuccess(false);
            setErrorText(e.response.data.message);
          });
      }

      setLoading(false);
    };
    Mint();
  }, []);

  useEffect(() => {
    if (mintSuccess === null) return;
    if (mintSuccess) {
      setTimeout(() => {
        navigate("/home");
      }, 5000);
    } else {
      setTimeout(() => {
        navigate("/home");
      }, 5000);
    }
  }, [mintSuccess]);

  console.log(errorText, "error text");

  return (
    <>
      {!isLoading ? (
        <div
          className={`mint-feedback-container  ${
            !mintSuccess ? "error-inner-texts" : ""
          }`}
        >
          <Logo />
          <div className="mint-feedback-inner-texts">
            {mintSuccess === true ? (
              <>
                {sourceCtx.isCimerMint === false ? (
                  <div className="feedback-msg">
                    <p
                      className="success-mint"
                      style={{ fontFamily: config.MUZEUM.titleFont, textTransform:'uppercase' }}
                    >
                {t('successMint')}
                    </p>
                    <p
                      className="mint-message"
                      style={{
                        color: config.MUZEUM.textColor,
                        fontFamily: config.MUZEUM.font,
                      }}
                    >
                    {t('successMintExperience')}
                    </p>
                    <div className="minted">
                      <Card
                        key={selectedCollection.id}
                        name={selectedCollection.name}
                        collectionUri={selectedCollection.uri}
                        collectionId={selectedCollection.id}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="feedback-msg">
                    <p
                      className="success-mint"
                      style={{ fontFamily: config.MUZEUM.titleFont, textTransform:'uppercase' }}
                    >
                       {t('successMint')}
                    </p>
                    <p
                      className="mint-message"
                      style={{
                        color: config.MUZEUM.textColor,
                        fontFamily: config.MUZEUM.font,
                      }}
                    >
                      {t('successMintCrest')}
                    </p>
                    <div className="minted"></div>
                  </div>
                )}
              </>
            ) : (
              <>
                {mintSuccess !== null && (
                  <>
                    {sourceCtx.isCimerMint === false ? (
                      <div className="feedback-msg">
                        <p
                          className="error-mint"
                          style={{ fontFamily: config.MUZEUM.titleFon, textTransform:'uppercase' }}
                        >
                          {t('errorMint')}
                        </p>
                        <p
                          className="mint-message"
                          style={{
                            color: config.MUZEUM.textColor,
                            fontFamily: config.MUZEUM.font,
                          }}
                        >
                          {errorText === "Already minted from collection"
                            ? "A kártya megszerzése sikertelen volt, mivel ez a kártya már megtalálható a beolvasott címen"
                            : ""}
                        </p>
                      </div>
                    ) : (
                      <div className="feedback-msg">
                        <p
                          className="error-mint"
                          style={{ fontFamily: config.MUZEUM.titleFont, textTransform:'uppercase' }}
                        >
                           {t('errorMint')}
                        </p>
                        <p
                          className="mint-message"
                          style={{
                            color: config.MUZEUM.textColor,
                            fontFamily: config.MUZEUM.font,
                          }}
                        >
                          {sourceCtx.isCimerMint && errorText.includes("Already minted token id:") && "Önnek már van családi címere"}
                        </p>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="loader">
          <ClipLoader
            color={"#999999"}
            loading={isLoading}
            size={140}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};

export default MintFeedback;
