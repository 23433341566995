import React, { useState, useEffect, useContext } from "react";
import cardImg from "../../assets/images/nft.png";
import axios from "axios";
import config from "../../config.json";
import { LanguageContext } from "../../context/languageContext";

const Card = ({ onClick, collectionUri, isOwned }) => {
  const langCtx = useContext(LanguageContext);

  const [titleHu, setTitleHu] = useState("élmény neve hu");
  const [titleRo, setTitleRo] = useState("élmény neve ro");
  const [titleEn, setTitleEn] = useState("élmény neve en");
  const [image, setImg] = useState(cardImg);

  useEffect(() => {
    const getMetadata = async () => {
      await axios
        .get(collectionUri)
        .then((response) => {
          if (response.data) {
            console.log(response.data);
            setTitleHu(response.data.nameHu);
            setTitleRo(response.data.nameRo);
            setTitleEn(response.data.nameEn);
            setImg(response.data.image);
          }
        })
        .catch((e) => {
          console.log(e, "error");
        });
    };
    getMetadata();
  }, []);

  return (
    <>
      <div className="card">
        <img
          className={isOwned === true ? "card-image" : "not-owned-card-image"}
          src={image}
          id="exp-img"
          onClick={onClick}
        />
        <p
          className="exp-name"
          style={{
            color: config.MUZEUM.titleColor,
            fontFamily: config.MUZEUM.titleFont,
          }}
        >
          {langCtx.language === "hu"
            ? titleHu
            : langCtx.language === "ro"
            ? titleRo
            : titleEn}
        </p>
      </div>
    </>
  );
};

export default Card;
