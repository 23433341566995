import React, {useContext} from "react";
import config from "../../config.json";
import { ClickSourceContext } from "../../context/clickSourceContext";

const Footer = () => {
  const ctx = useContext(ClickSourceContext);
  const isKiosk = ctx.isKiosk;

  const ccLink = (
    <a href="https://www.camelcoding.com/" target="_blank" className="ccLink" style={{color: config.MUZEUM.titleColor}}>
      CamelCoding
    </a>
  );

  return (
    <>
      {isKiosk === true ? (
        <div className="footer" style={{ color: config.MUZEUM.textColor }}>
          Powered by:  <span className="ccLink">CamelCoding</span>
        </div>
      ) : (
        <div className="footer" style={{ color: config.MUZEUM.textColor }}>
          Powered by: <span className="ccLink">CamelCoding</span>
        </div>
      )}
    </>
  );
};

export default Footer;
