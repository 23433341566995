import React, { useState, useContext } from "react";
import QrScanner from "../components/QRScanner";
import { ClickSourceContext } from "../context/clickSourceContext";

const Scanner = () => {
  const [walletAddress, setWalletAddress] = useState(null);
  const sourceCtx = useContext(ClickSourceContext);

  return (
    <>
    <QrScanner setWalletAddress={(address)=>{setWalletAddress(address)}}/>
    </>
  );
};

export default Scanner;
