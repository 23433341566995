import React from "react";
import Login from "../views/Login";
import Dashboard from "../views/Dashboard";
import Scanner from "../views/Scanner";
import MintFeedback from "../views/MintFeedback";
import Home from "../views/Home";
import LanguagePage from "../views/LanguagePage";

const routes = [
  {
    path: "/",
    element: <LanguagePage />,
    exact: true,
  },
  {
    path: "/login",
    element: <Login />,
    exact: true,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    exact: true,
  },
  {
    path: "/scanner",
    element: <Scanner />,
    exact: true,
  },
  {
    path: "/mint",
    element: <MintFeedback />,
    exact: true,
  },
  {
    path: "/home",
    element: <Home />,
    exact: true,
  },
];

export default routes;
