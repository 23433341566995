import React from "react";
import logo from "../assets/images/logo.svg";
import enLogo from "../assets/images/en_color.svg";
import roLogo from '../assets/images/ro_color.svg';
import cclogo from "../assets/images/CamelCoding-logo.svg";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../context/languageContext";
import { useContext } from "react";

const Logo = () => {
  const navigate = useNavigate();
  const langContext = useContext(LanguageContext);

  const currentPath = window.location.href.substring(
    window.location.href.lastIndexOf("/"),
    window.location.href.length
  );

  const goToHome = () => {
    if (currentPath === "/dashboard") {
      navigate("/");
    } else if (currentPath === "/mint") {
      navigate("/home");
    }
  };

  console.log(langContext.language, 'lang');

  return (
    <div className="logo-holder">
      <img src={langContext.language ===  'hu' ? logo : langContext.language ===  'ro' ? roLogo : enLogo} className="logo" onClick={goToHome}></img>
    </div>
  );
};

export default Logo;
